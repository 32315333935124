/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useTranslation } from 'react-i18next';
import { BillingPlans } from 'types';
import { CardListContainer } from 'ui';

import { PlanCard } from '../detail';

type PlanPrice = {
  price: string;
  billingPlan: keyof typeof BillingPlans;
  isGreen: boolean;
  promoPrice?: string;
  hasAdditionalInfo: boolean;
};

type PlanDetails = {
  [planType: string]: PlanPrice;
};

const planDetails: PlanDetails = {
  free: {
    billingPlan: 'plan1',
    isGreen: false,
    price: '0.245',
    hasAdditionalInfo: false,
  },
  standard: {
    billingPlan: 'plan8',
    isGreen: false,
    price: '0.240',
    hasAdditionalInfo: false,
  },
  exchange: {
    billingPlan: 'plan18',
    isGreen: false,
    price: '0',
    promoPrice: '0.017',
    hasAdditionalInfo: true,
  },
  greenFree: {
    billingPlan: 'plan13',
    isGreen: true,
    price: '0.248',
    hasAdditionalInfo: false,
  },
  greenStandard: {
    billingPlan: 'plan3',
    isGreen: true,
    price: '0.243',
    hasAdditionalInfo: false,
  },
  greenExchange: {
    billingPlan: 'plan17',
    isGreen: true,
    price: '0',
    promoPrice: '0.020',
    hasAdditionalInfo: true,
  },
};

interface Props {
  baseUrl: string;
}

export const PlanCardList = ({ baseUrl }: Props) => {
  const { t } = useTranslation('plans');

  return (
    <CardListContainer
      cardWidth="md"
      elements={Object.entries(planDetails).map(
        ([planType, { isGreen, price, billingPlan, promoPrice, hasAdditionalInfo }]) => (
          <PlanCard
            title={t(`plans.${planType}.title`)}
            subtitle={isGreen ? t(`plans.${planType}.subtitle`) : undefined}
            details={t(`plans.${planType}.details`, { returnObjects: true, promoPrice }) as string[]}
            price={
              planType.toLowerCase().includes('exchange')
                ? t('exchangePrice', { exchangePrice: price })
                : t('price', { price })
            }
            isGreen={isGreen}
            billingPlan={billingPlan}
            key={billingPlan}
            baseUrl={baseUrl}
            promoPrice={promoPrice}
            additionalInfo={hasAdditionalInfo ? t(`plans.${planType}.additionalInfo`) : undefined}
          />
        )
      )}
    />
  );
};
